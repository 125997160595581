<template>
  <div class="lot-types-edit animatedBox">
    <div class="container fluid">
      <fd-form @submit="submitForm">
        <div class="card mb-2 p-1">
          <h4 class="mainTitle mb-3">Edit Lot Type</h4>
          <div class="main-contents">
            <fd-select
              v-model="lotType.propertyTypeId"
              label="Property Type"
              class="mb-2"
              :options="propertyTypeOptions"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-input
              type="text"
              label="Lot Type"
              v-model="lotType.name"
              :validators="[validator.required]"
            ></fd-input>
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import { LotTypeModel } from "@/models";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  name: "lot-types-edit",
  data() {
    return {
      propertyTypeOptions: [],

      lotType: {
        propertyTypeId: "",
        name: ""
      },
      validator: {
        required: required
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await Promise.all([this.loadLotType(), this.getPropertyTypeOptions()]);
      this.$store.commit("setIsLoading", false);
    },
    async getPropertyTypeOptions() {
      let data = await this.$store.dispatch(
        "managePropertyTypes/getAllPropertyTypes",
        {
          limit: 200
        }
      );

      this.propertyTypeOptions = this._.cloneDeep(data.data);
    },
    async loadLotType() {
      const id = this.$route.params.id;
      try {
        const request = await this.$store.dispatch(
          "manageLotTypes/getLotType",
          id
        );

        this.lotType = LotTypeModel.getLotTypeResponse(
          this._.cloneDeep(request)
        );

        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageLotTypes/updateLotTypes",
          {
            id: id,
            data: LotTypeModel.postLotTypePayload(this.lotType)
          }
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lot type updated successfully."
        });

        this.$router.push({ path: "/manage-lot-types" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        this.$reportError(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.lot-types-edit {
  form {
    @extend %formDesign;
  }
}
</style>